*{
    font-family: Arial !important;
    letter-spacing: 0.05em;  
}
.custom-border-bottom {
    border-bottom: 1px solid #d4cece;
}
.page-heading {
    font-size: 13px !important;
    color: #454545 !important;
    font-weight: bold !important;
    letter-spacing: 1.19077px;
    font-style: normal;
    line-height: 16px;
}

.btn-login {
    color: #FFFFFF; 
    background-color: #DC291E; 
    margin-bottom: 15% !important;
    font-family: Arial;
    letter-spacing: 0.09em;
    font-size: 14px;
}
.btn-group-nbc {
    font-family: Arial;
}
.btn-next {
    color: #FFFFFF; 
    background-color: #DC291E; 
    /* margin-top: 15% !important; */
    /* margin-bottom: 15% !important; */
    letter-spacing: 0.09em;
    font-size: 13px;
    width: 30%;
}

.btn-back {
    color: #DC291E; 
    border-color: #DC291E;
    letter-spacing: 0.09em;
    font-size: 13px;
    width: 30%;
}
.btn-cancel {
    color: #DC291E; 
    background-color: #FFFFFF; 
    border-color: #FFFFFF;
    letter-spacing: 0.09em;
    font-size: 13px;
    width: 30%;
}
.container-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark {
    background-color: #c53609;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.errMessage {
    color: #c53609;
    font-size: 12px;
}
ngb-timepicker .btn-link{
    color: #DC291E;
}
ngb-timepicker .ngb-tp-input-container .btn-check:checked+.btn,
ngb-timepicker .ngb-tp-input-container .btn.active, 
ngb-timepicker .ngb-tp-input-container .btn:hover, 
ngb-timepicker .ngb-tp-input-container .btn.show, 
ngb-timepicker .ngb-tp-input-container .btn:first-child:active, 
ngb-timepicker .ngb-tp-input-container :not(.btn-check)+.btn:active {
    color: #DC291E;
}
ngb-timepicker .btn-outline-primary{
    color: #DC291E;
    border-color: #DC291E;
}

ngb-timepicker .ngb-tp-meridian .btn-check:checked+.btn,
ngb-timepicker .ngb-tp-meridian .btn.active, 
ngb-timepicker .ngb-tp-meridian .btn:hover, 
ngb-timepicker .ngb-tp-meridian .btn.show, 
ngb-timepicker .ngb-tp-meridian .btn:first-child:active, 
ngb-timepicker .ngb-tp-meridian :not(.btn-check)+.btn:active {
    color: #FFFFFF;
    background-color: #DC291E;
    border-color: #DC291E;
}

ngb-popover-window .popover-header{
    background-color: #F2F6F9;
}